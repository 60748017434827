import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { ToastrService } from "ngx-toastr";
import { BaseUrls } from "./app.config";
import { City, Culture, FileUpload, State } from "./app.model";
import { TokenStorage } from "./auth/token-storage.service";
import { Util } from "./shared/utils/utils";

declare let require: any;
require('aws-sdk/dist/aws-sdk');

export interface FilesUploaded {
  url: string;
}

@Injectable()
export class UploadService {
  private AWSService = (<any>window).AWS;

  private user = Util.getLogin();

  private http = inject(HttpClient);
  private tokenStorage = inject(TokenStorage);

  constructor() {}

  async upload(files: FileUpload[], origin: string, path: string): Promise<FilesUploaded[]> {
    try {
      const fileUploadPromises = files.map(file => this.createFileObject(file));

      const uploadedFiles = await Promise.all(fileUploadPromises);
  
      const urls = uploadedFiles.map(async (uploadedFile) => {
        const newfile = {
          name: uploadedFile.name,
          src: uploadedFile.src
        }

        if (!this.user) {
          return await this.savePublicFile(newfile, origin, path)
        }

        return await this.saveFile(newfile, origin, path)
      })

      const test = await Promise.all(urls)

      return test.map(url => {
        return {
          url: url.url
        }
      })
      
    } catch (error) {
      console.log(error)
      throw new Error(`Error uploading files: ${JSON.stringify(error, null, 2)}`)
    }
  }

  async saveFile(
    uploadedFile: { name: string, src: string | ArrayBuffer  }, 
    origin: string, 
    path: string
  ): Promise<FilesUploaded> {
    const headers = new HttpHeaders({
			'Content-Type': 'application/json',
      'Authorization': this.tokenStorage.getAccessTokenString()
		});

		return await firstValueFrom(
			this.http.post<any>(
      `${BaseUrls.newe}/v1/image/upload?fileName=${uploadedFile.name}&origin=${origin}&path=${path}`,
      uploadedFile.src,
			{ headers: headers }
		));
  }

  async savePublicFile(
    uploadedFile: { name: string, src: string | ArrayBuffer  }, 
    origin: string, 
    path: string
  ): Promise<FilesUploaded> {
    const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		return await firstValueFrom(
			this.http.post<any>(
      `${BaseUrls.newe}/v1/image/upload-public?fileName=${uploadedFile.name}&origin=${origin}&path=${path}`,
      uploadedFile.src,
			{ headers: headers }
		));
  }

  convertFileToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
  
      fileReader.onloadend = () => resolve(fileReader.result);
      fileReader.onerror = (error) => reject(error);
  
      fileReader.readAsDataURL(file);
    });
  }

  private async createFileObject(file: FileUpload): Promise<{ name: string, src: string | ArrayBuffer }> {
    const base64File = await this.convertFileToBase64(file.file);
    return {
      name: file.path.split('.')[0],
      src: base64File
    };
  }
}

